import styles from "./footer.module.scss";
import { Link, useShared } from "@interactivevision/visitor-react";
import { SectionWrapper } from "./wrappers";
import { route } from "@interactivevision/routing";
import CompanyLogo from "@assets/images/company-logo.png";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "@assets/icons/email.svg";
import { ReactComponent as PinIcon } from "@assets/icons/pin.svg";
import { ReactComponent as Linkedin } from "@assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "@assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "@assets/icons/instagram.svg";
import { ReactComponent as IvLogo } from "@assets/icons/iv-logo.svg";
import { $t } from "@interactivevision/translations";
import Paragraph from "../typography/paragraph";

export default function Footer() {
  const shared = useShared();

  return (
    <footer className={styles.footer}>

      <SectionWrapper className={styles.middle}>
        <Link href={route( "homepage_module" )} className={styles.logo}>
          <img src={CompanyLogo} alt='Solo' />
        </Link>

        <div className={styles.contact}>
          <div className={styles.box}>
            <PhoneIcon />
            <div className={styles.content}>
              <span>Telefon</span>
              <a href='tel:533351315'>
                +48 533 351 315
              </a>
            </div>
          </div>
          <div className={styles.box}>
            <EmailIcon />
            <div className={styles.content}>
              <span>Adres e-mail</span>
              <a href='mailto:agnieszka.armata@grupasolo.pl'>
                agnieszka.armata@grupasolo.pl
              </a>
            </div>
          </div>
          <div className={styles.box}>
            <PinIcon />
            <div className={styles.content}>
              <span>Brandwica 37-464, Poland </span>
              <span><b>ul. Orzeszkowej 28</b></span>
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper className={styles.lower}>
        <div className={styles.socials}>
          <a href="https://www.linkedin.com/company/grupa-solo-jako%C5%9B%C4%87-zobowi%C4%85zuje/?originalSubdomain=pl" title='Linkedin' rel="_noopener">
            <Linkedin />
          </a>
          <a href="https://www.facebook.com/ProducentOkienGrupaSolo" title='Facebook' rel="noopener" target="_blank">
            <Facebook />
          </a>
          <a href="https://www.instagram.com/grupasolo/" title='Instagram' rel="noopener" target="_blank">
            <Instagram />
          </a>
        </div>

        <div className={styles.rights}>
          <Paragraph size={8}>{$t( "general.footer-rights" )}</Paragraph>
          <a href='https://interactivevision.pl/' target='_blank' rel='noreferrer' className={styles.realisation}>
            <span>{$t( "general.footer-realisation" )}</span>
            <IvLogo />
          </a>
        </div>
      </SectionWrapper>
    </footer>
  );
}
