import styles from './navbar.module.scss';
import { Link } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import CompanyLogo from '@assets/images/company-logo.png';
import { $t } from '@interactivevision/translations';
import { Button } from '../components/button';
import { useAtom, useSetAtom } from "jotai";
import { atomModalQuestionnaire } from '../atom/atoms';


export default function Navbar() {
  const setQuestionnaire = useSetAtom( atomModalQuestionnaire );


  return (
    <nav className={styles.navbar} id="navbar">
      <div className={styles.content}>
        <Link href={route( 'homepage_module' )} className={styles.logo} title={import.meta.env.VITE_APP_NAME}>
          <img src={CompanyLogo} alt="Solo" />
        </Link>
        <Button className={styles.contact} aria-label={$t( 'general.contact_us' )} onClick={() => setQuestionnaire( true )}>
          <span className={styles.text}>
            {$t( 'general.check_usage' )}
          </span>
          <span className={styles.mobile}>Sprawdź</span>
        </Button>
      </div>
    </nav>
  );
};
