import { createElement, ComponentType } from "react";
import { RouterProps } from "@interactivevision/visitor-react";

type SetupOptions = {
    router: ComponentType<RouterProps>;
    props: RouterProps;
};

import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: "GTM-572Z9HT",
};

TagManager.initialize( tagManagerArgs );

export default function app( { router, props }: SetupOptions ) {
    return createElement( router, props );
}
